
import {defineComponent, onMounted, reactive, toRefs, computed, ref} from "vue";
import ApiArticle from "@/request/apis/api_article";
import ApiLogistics from "@/request/apis/api_logistics";
import {articleTypes, IArticleCategory, IArticleItem} from "@/models/article_model";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import {EditOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons-vue'
import ImageView from '@/components/ImageView.vue'
import PageUtil from "@/utils/page_util";
import {IArticlePlatform, IArticlePlatformCate} from "@/models/logistics_article_model";

interface IState {
  list: IArticlePlatformCate[],
  total: number;
  loading: boolean;
  searches: any
  editItem?: IArticlePlatformCate,
  articlePlatVisible: boolean
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '描述',
    dataIndex: "title",
    key: 'title',
  },
  {
    title: '排序',
    dataIndex: 'weight',
    key: 'weight',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
    align: 'right'
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: 250
  },
];

export default defineComponent({
  name: 'ArticleCateList',
  components: {EditOutlined, CheckOutlined, CloseOutlined, ImageView},
  setup() {
    const platSet = ref();
    const state: IState = reactive({
      platformList: [],
      list: [],
      total: 0,
      loading: false,
      editItem: undefined,
      searches: {
        page: 1,
        limit: 10,
        keywords: ""
      },
      articlePlatVisible: false
    })


    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    // 转换筛选list数据
    const exchangeListData = (list:IArticlePlatformCate[]) => {
      list.map(item => {
        item.children = item.children && item.children.length ? item.children : undefined;
        if (item.children && item.children.length) {
          item.children = exchangeListData(item.children);
        }
      })
      return list;
    }

    //获取平台列表
    const getList = async () => {
      state.loading = true
      const res = await ApiLogistics.articleCateTree({})
      state.loading = false
      if (res && res.error_code === 0) {
        state.list = exchangeListData(res.data || [])
      }
    }

    // 去新增编辑
    const goSet = (item?: IArticlePlatform) => {
      let params = {}
      if (item) {
        params = {id: item.id}
      }
      router.push({path: '/articleCate/set', query: params}).then()
  }
    // 详情
    const goDetail = (item: IArticleItem) => {
      router.push({path: '/article/detail', query: {id: item.id}}).then()
    }

    // 添加分类
    const addCate = () => {
      goSet()
    }

    // 修改状态
    const changeStatus = async (item: IArticlePlatformCate) => {
      let params = {
        "id": item.id,
        "status": item.status ? 0 : 1
      }
      state.loading = true
      const res = await ApiLogistics.articleCateStatusChange(params)
      state.loading = false
      if (res && res.error_code === 0) {
        getList()
      }
    }

    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      goSet,
      goDetail,
      pagination,
      handleTableChange,
      articleTypes,
      onSearch,
      addCate,
      changeStatus
    }
  }
})
